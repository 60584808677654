<!-- 新增阳性jzy -->
<template>
  <div class="main">
    <div class="top">
      <p class="top_left">
        <i class="el-icon-back" style="margin-right: 20px" @click="goBack"></i>
        <span>{{ title }}</span>
      </p>
      <p class="top_right"><span class="xinxin">*</span>为必填项</p>
    </div>
    <el-divider></el-divider>

    <div class="conten">
      <el-form :model="regitsterInfo" :rules="regitsterInfoRules" ref="regitsterInfoForm" label-width="200px">
        <!-- 任务信息 -->
        <div class="register-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">任务信息</p>
          </div>
          <!-- {{regitsterInfo}} -->
          <div class="content-item">
            <el-form-item class="formitem" label="任务来源：" prop="taskSourceCode">
              <el-cascader v-if="echoAddress" :disabled="this.type == 'addlddj'" class="select-width"
                ref="taskSourceRef" v-model="regitsterInfo.taskSourceCode" :props="{ ...props, checkStrictly: true }"
                @change="handleTaskSource" clearable></el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="信息类别：" prop="infoType">
              <div class="ph">
                <el-radio-group :disabled="this.type == 'addlddj'" @change="infoTypeChange"
                  v-model="regitsterInfo.infoType" class="select-width2ax">
                  <el-radio v-for="item in infoType" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <el-input v-show="regitsterInfo.infoType == 3" class="other-input" v-model="regitsterInfo.infoTypeOther"
                  placeholder="请输入其他信息类别"></el-input>

                <el-input :disabled="this.type == 'addlddj'" v-show="regitsterInfo.infoType == 1" class="other-input"
                  v-model="regitsterInfo.letterNo" placeholder="协查函编号" :maxlength="32"></el-input>
              </div>
            </el-form-item>
          </div>
          <!-- 如果是“流调登记”按钮进来的禁用 -->
          <div class="content-item">
            <el-form-item class="formitem" label="事件名称：" prop="eventName">
              <el-select class="select-width" v-model="regitsterInfo.eventId" clearable filterable placeholder="请输入事件名称"
                @change="issueName" :disabled="this.type == 'addlddj'">
                <el-option v-for="item in eventNameSelect" :key="item.id" :label="item.eventName" :value="item.id"
                  @click.native="ldcs(item)"></el-option>
              </el-select>
            </el-form-item>
            <!-- 如果是“流调登记”按钮进来的禁用 -->
            <el-form-item class="formitem" label="调查场所：">
              <el-select class="select-width" v-model="regitsterInfo.surveyAddress" filterable clearable
                placeholder="请选择调查场所">
                <el-option v-for="item in eventcsSelect" :key="item.id" :label="item.placeName" :value="item.placeName">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="任务描述：" prop="surveyResultDes">
              <el-input type="textarea" v-model="regitsterInfo.taskRemark" placeholder="请输入内容" maxlength="200"
                show-word-limit class="select-width" :disabled="this.type == 'addlddj'"></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="附件:">
              <el-upload class="select-width" :action="upload" accept=".jpg, .png, .doc, .docx, .pdf, .xls, .xlsx"
                :before-upload="beforeUpload" :on-preview="handlePreview" :limit=5 :before-remove="beforeRemove"
                :on-remove="handleRemove" :on-success="handelSuccess" multiple :on-error="handelError"
                :headers="myHeaders" :file-list="fileList">
                <el-button size="small" icon="el-icon-upload">上传文件</el-button>
                <span slot="tip" class="el-upload-tip">
                  支持格式：.jpg .png .doc .docx .pdf .xls
                  .xlsx，单个文件不能超过20MB，最多上传五个文件
                </span>
              </el-upload>
            </el-form-item>
          </div>
        </div>

        <!-- 首次流调核心信息表 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">首次流调核心信息表</p>
          </div>
          <div class="content-item1">
            <el-form-item class="special1" label="调查对象类别：" prop="respondentsCategory">
              <div class="ph">
                <el-radio-group v-model="regitsterInfo.respondentsCategory" @change="surveyCategoryChange()"
                  class="select-width2ax">
                  <el-radio v-for="item in surveuInfoCase" :label="item.value" :key="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
                <el-input v-if="regitsterInfo.respondentsCategory == '5'" class="select-width2" maxlength="30"
                  type="text" show-word-limit v-model="regitsterInfo.respondentsCategoryOther" placeholder="请输入其他类别">
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="姓名：" prop="name">
              <el-input v-model="regitsterInfo.name" placeholder="请输入姓名" class="select-width"></el-input>
            </el-form-item>
            <el-form-item class="formitem" label="性别：">
              <el-radio-group v-model="regitsterInfo.sex" class="select-width">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="证件类型：" prop="cardType">
              <el-select v-model="regitsterInfo.cardType" clearable placeholder="证件类型" class="select-width">
                <el-option v-for="item in typeCertificate" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="证件号：" prop="cardNo">
              <el-input class="select-width" v-model.trim="regitsterInfo.cardNo" placeholder="请输入证件号"
                @blur="idCodeCount(regitsterInfo.cardNo)" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="联系电话：" prop="linkPhone">
              <el-input class="select-width" maxlength="11" v-model.trim="regitsterInfo.linkPhone"
                placeholder="请输入联系电话" />
            </el-form-item>
            <el-form-item class="formitem" label="年龄：" prop="age">
              <el-input class="select-width" type="number" v-model="regitsterInfo.age" placeholder="请输入年龄" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="职业：" prop="occupation">
              <el-select v-model="regitsterInfo.occupation" filterable clearable placeholder="请选择职业"
                class="select-width">
                <el-option v-for="item in surveyOccupation" :key="item.label" :label="item.label" :value="item.label">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="工作单位/学校：">
              <el-input maxlength="50" type="text" class="select-width" show-word-limit v-model="regitsterInfo.workUnit"
                placeholder="工作单位/学校" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="现住地：" prop="currentAddressCode">
              <el-cascader v-if="echoAddress" clearable ref="currentAddressRef" class="select-width"
                v-model="regitsterInfo.currentAddressCode" :props="arearProps" @change="handelCurrentAddress">
              </el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="详细地址：" prop="currentAddressDetail">
              <el-input type="text" class="select-width" v-model="regitsterInfo.currentAddressDetail"
                placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 同住人员情况-->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">同住人员情况</p>
          </div>

          <div class="content-item">
            <el-form-item class="textarea">
              <el-input size="medium" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea"
                placeholder="请按照姓名+证件号码+电话号码+关系的格式填写+人员用逗号隔开" v-model="regitsterInfo.cohabitantSituation"
                maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>
          </div>

        </div>

        <!-- 疫苗接种情况 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">疫苗接种情况</p>
          </div>

          <div class="content-item">
            <el-form-item class="special" label="是否接种了新冠病毒疫苗：" prop="vaccination">
              <el-radio-group @change="isVaccination()" v-model="regitsterInfo.vaccination" class="select-width">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div v-show="regitsterInfo.vaccination == 1">
            <div class="content-item">
              <el-form-item class="special" label="接种了哪种新冠病毒疫苗：" prop="vaccinationType">
                <el-checkbox-group @change="isOtherVaccination" v-model="regitsterInfo.vaccinationType">
                  <el-checkbox label="北京生物">北京生物</el-checkbox>
                  <el-checkbox label="科兴中维">科兴中维</el-checkbox>
                  <el-checkbox label="智飞生物">智飞生物</el-checkbox>
                  <el-checkbox label="其他">其他
                    <template v-if="regitsterInfo.vaccinationType.includes('其他')">
                      <el-input style="margin-left:10px;width:200px" v-model="regitsterInfo.vaccinationTypeOther"
                        placeholder="请输入" />
                    </template>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <div class="content-item">
              <el-form-item class="special" label="接种了几剂新冠病毒疫苗：" prop="inoculationNum">
                <el-radio-group v-model="regitsterInfo.inoculationNum" class="select-width">
                  <el-radio label="1">1剂</el-radio>
                  <el-radio label="2">2剂</el-radio>
                  <el-radio label="3">3剂</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="content-item">
              <el-form-item class="special" label="是否完成了疫苗全程接种：" prop="finishVaccination">
                <el-radio-group v-model="regitsterInfo.finishVaccination" class="select-width">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item class="formitem" label="最后针次时间：">
                <el-date-picker class="select-width" v-model="regitsterInfo.lastNeedlesDate" type="date"
                  placeholder="选择时间" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>

        <!-- 临床表现及实验室检测信息 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">临床表现及实验室检测信息</p>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="是否有自觉症状：" prop="clinicalSymptoms">
              <el-radio-group @change="isClinicalSymptoms()" v-model="regitsterInfo.clinicalSymptoms"
                class="select-width">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
                <el-radio label="2">不清楚</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="regitsterInfo.clinicalSymptoms == '1'" class="formitem" label="最早出现症状时间：">
              <el-date-picker class="select-width" v-model="regitsterInfo.firstSymptomDate" type="datetime"
                placeholder="选择时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>
          </div>
          <div class="content-item9">
            <el-form-item class="fouritem" label="首次核酸阳性的采样日期：" prop="firstNucleicPositiveDate">
              <el-date-picker class="select-width" v-model="regitsterInfo.firstNucleicPositiveDate" type="datetime"
                placeholder="选择时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>

            <el-form-item class="threeitem" label-width="10px">
              <el-input v-model="regitsterInfo.ntarget" placeholder="N靶标" />
            </el-form-item>
            <el-form-item class="threeitem" label-width="10px">
              <el-input v-model="regitsterInfo.abTarget" placeholder="ORF1ab靶标" />
            </el-form-item>
            <el-form-item class="threeitem" label-width="10px">
              <el-input v-model="regitsterInfo.reagent" placeholder="试剂" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="textarea" label="阳性采样前7天核酸情况：">
              <el-input size="medium" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea"
                placeholder="核酸时间+核酸结果+（单采/混采），多个逗号隔开" v-model="regitsterInfo.positiveNucleicSituation" maxlength="500"
                show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 流行病学信息 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">流行病学信息</p>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="是否接触过感染者：">
              <el-radio-group @change="isContactInfected" v-model="regitsterInfo.contactInfected" class="select-width">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
                <el-radio label="2">不清楚</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="regitsterInfo.contactInfected == '1'" class="formitem" label="关联病例姓名">
              <el-input maxlength="20" type="text" class="select-width" show-word-limit
                v-model="regitsterInfo.associatedCaseName" placeholder="请输入关联病例姓名" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="接触时间：">
              <el-date-picker class="select-width" v-model="regitsterInfo.contactDate" type="datetime"
                placeholder="选择时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>
            <el-form-item class="formitem" label="接触地点">
              <el-input maxlength="50" type="text" class="select-width" show-word-limit
                v-model="regitsterInfo.contactPlace" placeholder="请输入接触地点" />
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special1" label="接触方式：">
              <div class="ph">
                <el-radio-group v-model="regitsterInfo.contactMode" @change="contactModeChange()"
                  class="select-width2ax">
                  <el-radio v-for="item in contactWays" :label="item.value" :key="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
                <el-input v-if="regitsterInfo.contactMode == '5'" class="select-width2" maxlength="30" type="text"
                  show-word-limit v-model="regitsterInfo.contactModeOther" placeholder="请输入其他接触方式">
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special1" label="接触地点类型：">
              <div class="ph">
                <el-radio-group v-model="regitsterInfo.contactPlaceType" @change="contactPlaceChange()"
                  class="select-width2ax">
                  <el-radio v-for="item in contactPlace" :label="item.value" :key="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
                <el-input v-if="regitsterInfo.contactPlaceType == '5'" class="select-width2" maxlength="30" type="text"
                  show-word-limit v-model="regitsterInfo.contactPlaceTypeOther" placeholder="请输入其他接触地点类型">
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="末次接触时间：">
              <el-date-picker class="select-width" v-model="regitsterInfo.lastContactDate" type="datetime"
                placeholder="选择时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"></el-date-picker>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="发现方式：" prop="discoveryMethod">
              <el-select v-model="regitsterInfo.discoveryMethod" filterable="" clearable placeholder="请选择发现方式"
                class="select-width">
                <el-option v-for="item in discoverySelect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label="风险区域：" prop="riskArea">
              <el-select v-model="regitsterInfo.riskArea" clearable placeholder="请选择风险区域" class="select-width">
                <el-option v-for="item in riskAreaSelect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="formitem" label="人员类型：">
              <el-select v-model="regitsterInfo.personnelType" filterable="" clearable placeholder="请选择人员类型"
                class="select-width">
                <el-option v-for="item in peopleTypeSelect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="content-item">
            <el-form-item class="formitem" label="感染途径：" prop="routeInfection">
              <el-select v-model="regitsterInfo.routeInfection" filterable="" clearable placeholder="请选择感染途径"
                class="select-width" @change="routeInfectionChange">
                <el-option v-for="item in infectionWaysSelect" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formitem" label-width="0px">
              <el-input v-if="regitsterInfo.routeInfection == '7'" class="select-width3" maxlength="30" type="text"
                show-word-limit v-model="regitsterInfo.routeInfectionOther" placeholder="请输入其他感染方式">
              </el-input>
            </el-form-item>





          </div>
        </div>



        <!-- 其他可疑暴露史 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">其他可疑暴露史</p>
          </div>
          <div class="content-item">
            <el-form-item class="textarea">
              <el-input size="medium" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" placeholder="请输入"
                v-model="regitsterInfo.otherSuspiciousExposureHistory" maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 转运隔离及核酸情况（如果是密接则填写） -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">转运隔离及核酸情况（如果是密接则填写）</p>
          </div>
          <div class="content-item">
            <el-form-item class="textarea">
              <el-input size="medium" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" placeholder="请输入"
                v-model="regitsterInfo.transportIsolationNucleic" maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 暴露的问题和传播风险 -->
        <div class="basic-info">
          <div class="title" style="margin-left:10px">
            <span class="blue"></span>
            <p style="font-size: 16px">暴露的问题和传播风险</p>
          </div>
          <div class="content-item">
            <el-form-item class="textarea">
              <el-input size="medium" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" placeholder="请输入"
                v-model="regitsterInfo.exposedProblemsRisks" maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>
          </div>
        </div>






        <br />
      </el-form>
    </div>
    <div class="btnbox"></div>
    <div class="btns">
      <el-button type="primary" size="medium" :loading="submitLoading" @click="submitForm">提交</el-button>
      <!-- <el-button @click="resetForm">重置</el-button> -->
    </div>
    <!-- 提交对话框（警告框） -->
    <el-dialog title="警告提示" :visible.sync="dialogVisible" width="40%" :before-close="closeDialog">
      <el-row class="warning" align="middle">
        <i class="el-icon-warning-outline" style="color: #ff9900;font-size: 26px;font-weight: bold;
        margin-right: 10px;"></i>
        <span style="font-weight: bold; font-size: 16px">此人员近10天有重复阳性调查信息，请联系核实</span>
        <br />
        <br />
        <div class="notification" v-for="(item, index) in dialogMessage" :key="index">
          <div class="NameNotification">
            {{ item.name }}
          </div>
          <div class="CardIdNotification">
            {{ item.cardNo }}
          </div>
          <div class="OrgPhoneNotification">
            {{ item.org }}({{ item.linkPhone ? item.linkPhone : "-" }})
          </div>
          <div class="TypeNotification">
            <span v-if="item.type == '隔离'">
              <el-tag size="mini">隔离</el-tag>
            </span>
            <span v-else>
              <el-tag type="danger" size="mini">流调</el-tag>
            </span>
          </div>
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="continueSubmitLoading" @click="postSubmit">继续提交</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _debounce } from "@/utils/utils.js";
import { http } from "@/api/index";
import {
  saveSurveyInfoList, //批量录入保存
  batchCheckIsReg, //批量录入查重
  getEventListApi,
  getPlaceList,
  saveSurveyPositiveInfo,//保存阳性新增
  getPositiveInfo,//根据id查询信息
  updateSurveyPositive,//阳性编辑保存
  checkPositiveIsReg,//阳性新增查重

} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { getToken } from "@/utils/auth";
import { getBusinessOrgList } from "@/api/SystemManagement/index"; //根据业务状态查询机构列表
// import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import {
  getMedicalOrgList,
  getUserListByPeopleTypeApi,
} from "@/api/SystemManagement/index";
// import { configKey } from "@/api/SystemManagement/isolationpointmanagement";
import constants from "@/common/constants";
import { SURVEY_CASE, INFO_TYPE, SURVEY_OCCUPATION, CONTACT_MODE, CONTACT_PLACE, DISCOVER_METHOD, PEOPLE_TYPE, INFECTION_ROUTE } from "@/common/constants";
import DialogInfo from "@/components/DialogInfo.vue";
import { isIDCard, isPhone } from "@/utils/validate";
import { mapState, mapMutations } from "vuex";
import {
  getIdNumberInfo,
  // downloadExportFile,
  // importFile,
  importUrlFile,
} from "@/utils/utils";
import { Loading } from "element-ui";
// import { number } from 'echarts';

export default {
  name: "addPositiveCase",
  props: {
    // 接收从父组件传递过来的btn类型，addlidj ，add ，edit
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    rowdata: {
      type: [],
      default: "",
    },
  },
  data() {
    return {};
  },
  components: {
    DialogInfo,
  },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    // 需要搬到residentinfo里面
    const cardNoCheck = (rule, value, callback) => {
      if (!isIDCard(value) && this.regitsterInfo.cardType == "1") { //身份证为1 才验证
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };

    return {
      echoAddress: false,//回显时候标记
      continueSubmitLoading: false,
      submitLoading: false, //提交按钮loading
      isReg: [], // 查重人员数组
      selectArr: [],
      concentrateOrgList: [],
      title: "新增阳性病例流调登记",
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
      upload: "/ohealth/api/v1/system/common/uploadFile", //文件上传地址
      dialogVisible: false, //查重对话框
      // preTaskStatus: "", //任务状态
      // hxpd: false,
      fileList: [], //回显时文件列表
      tempFileList: [], //备份文件列表
      eventcsSelect: [],
      dialogMessage: {}, //对话框
      surveyUserNameSelect: [], //调查人员项
      eventNameSelect: [], //事件名称项
      surveyObjectBelongOrgSelect: [], //所属社区
      surveuInfoCase: SURVEY_CASE,//调查对象类别
      infoType: INFO_TYPE, //信息类别数组
      surveyOccupation: SURVEY_OCCUPATION,//职业类型
      contactWays: CONTACT_MODE,//接触方式数组
      contactPlace: CONTACT_PLACE,//接触地点类型
      discoverySelect: DISCOVER_METHOD,//发现方式
      infectionWaysSelect: INFECTION_ROUTE,//感染途径
      riskAreaSelect: [
        { value: '1', label: '临时管控区' },
        { value: '2', label: '高风险区' }
      ],
      peopleTypeSelect: PEOPLE_TYPE,//人员类型
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型


      // regitsterInfo 发请求registerinfo为survey字段
      regitsterInfo: {
        id: "",
        taskSource: "", //任务来源地址
        taskSourceCode: [], //任务来源编码
        infoType: "1", //信息类别：默认协查函
        letterNo: "",//协查函编号
        infoTypeOther: "",//其他信息类别
        surveyAddress: "", //调查场所
        eventId: "", //事件名称id
        eventName: "", //事件名称name
        taskRemark: "", //任务描述说明
        respondentsCategory: "1", //调查对象类别
        respondentsCategoryOther: "", //调查对象类别其他描述
        sex: "",
        cardType: "1", //证件类型
        cardNo: "", //证件号码
        linkPhone: "", //联系电话
        age: "",//年龄
        occupation: "",//职业
        workUnit: "",//工作单位/学校
        currentAddress: "", //现住地
        currentAddressCode: [], //现住地code数组
        currentAddressDetail: "",//详细地址
        currentAddressGdTuid: "",//现住地高德经纬度
        currentAddressBdTuid: "",//现住地百度经纬度
        cohabitantSituation: "", //同住情况
        vaccination: "",//是否接种了新冠病毒疫苗(0-否 1-是)
        vaccinationType: [],//接种何种新冠病毒疫苗
        vaccinationTypeOther: "",//接种其他新冠肺炎疫苗
        inoculationNum: "",//接种了几剂新冠病毒疫苗(1-1剂；2-2剂；3-3剂)
        finishVaccination: "",//是否完成了新冠病毒疫苗全程接种（1-是 0-否 ）
        lastNeedlesDate: null, //最后针次时间
        clinicalSymptoms: "",//是否有自觉症状
        firstSymptomDate: null,//最早出现症状时间
        firstNucleicPositiveDate: null,//首次核酸阳性的采样日期
        ntarget: "",//N靶标
        abTarget: "",//ORF1ab 靶标
        reagent: "",//试剂
        positiveNucleicSituation: "",//阳性采样前7天核酸情况

        contactInfected: "",//是否接触过感染者
        associatedCaseName: "",//关联病例姓名
        contactDate: null,//接触时间
        contactPlace: "",//接触地点
        contactMode: "",//接触方式
        contactModeOther: "",//接触方式其他描述
        contactPlaceType: "",//接触地点类型
        contactPlaceTypeOther: "",//接触地点其他描述
        lastContactDate: null,//末次接触时间
        discoveryMethod: "",//发现方式
        riskArea: "",//风险区域(1-临时管控区；2-高风险区)
        personnelType: "",//人员类型

        routeInfection: "",//感染途径
        routeInfectionOther: "",//其他感染途径



        otherSuspiciousExposureHistory: "",//其他可疑暴露史
        transportIsolationNucleic: "",//转运隔离及核酸情况
        exposedProblemsRisks: "",//暴露的问题和传播风险



        surveyUserName: JSON.parse(localStorage.getItem("userdoctor")).nickName,//调查人员名称
        surveyUserId: JSON.parse(localStorage.getItem("userdoctor")).id,//调查人员ID
        surveyUserPhone: JSON.parse(localStorage.getItem("userdoctor")).linkPhone,//调查人员电话

        // surveyDate: new Date(), //选择调查时间，默认当前

        // surveyResult: "3",
        // surveyResultDes: "", //流调信息说明
        // surveyObjectBelongArea: "",
        // surveyObjectBelongAreaCode: [],
        // surveyObjectBelongOrg: "",
        // surveyObjectBelongOrgCode: "",
      },

      typepopSelect: [],
      regitsterInfoRules: {
        taskSourceCode: [
          { required: true, message: "请选择任务来源", trigger: "change" },
        ],
        infoType: [
          { required: true, message: "请选择信息类别", trigger: "change" },
        ],
        respondentsCategory: [
          { required: true, message: "请选择调查对象类别", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        cardType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        cardNo: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: cardNoCheck, trigger: "blur" },
        ],
        linkPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: phoneCheck, trigger: "blur" },
        ],
        occupation: [
          { required: true, message: "请选择职业", trigger: "change" },
        ],
        currentAddressCode: [
          { required: true, message: "请选择现住地", trigger: "change" },
        ],
        currentAddressDetail: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" },
        ],
        clinicalSymptoms: [
          { required: true, message: "请选择自觉症状", trigger: "change" },
        ],
        firstNucleicPositiveDate: [
          { required: true, message: "请选择首次核酸阳性的采样日期", trigger: "change" },
        ],
        discoveryMethod: [
          { required: true, message: "请选择发现方式", trigger: "change" },
        ],
        riskArea: [
          { required: true, message: "请选择风险区域", trigger: "change" },
        ],
        routeInfection: [
          { required: true, message: "请选择感染途径", trigger: "change" },
        ],


        // surveyDate: [
        //   { required: true, message: "请选择调查时间", trigger: "change" },
        // ],


      },
      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node, '输出node，node00000')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log(node,resolve,'输出node，resolve1111111111')
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            // console.log(formm,'输出formm22222222222222')
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  watch: {
    "regitsterInfo.currentAddressDetail"() {
      this.getBmap();
    },
    "regitsterInfo.currentAddress"() {
      this.getBmap();
    },
  },
  created() {
    //常量
    this.echoAddress = this.type == "edit" ? false : true;
    
    this.getUserListByPeopleType(); //获取调查人员


  },
  mounted() {
    this.getEventList(); //获取事件名称

    // 如果是新增阳性，任务所属地区和现住地动态默认，流调登记bn点进来的只需要默认现住地
    if (this.type == 'addPositive') {
      console.log(this.type, '打印出来为addPositive')
      this.regitsterInfo.taskSourceCode = JSON.parse(
        localStorage.getItem("addressThreeCode")
      ).split(",");
      this.regitsterInfo.taskSource = JSON.parse(
        localStorage.getItem("addressThreeDetail")
      );
      this.regitsterInfo.currentAddressCode = this.$store.state.defaultAddress.addressFourCode.split(',');//四级现住地code
      this.regitsterInfo.currentAddress = this.$store.state.defaultAddress.addressFourDetail;//四级现住地文字
    }





    //如若是 流调登记按钮点进来的部分（接口也用save），现住地需要默认，任务来源根据流调登记来的信息回显并且禁用相关表单
    // 如果是流调登记进来的，已在任务那里添加的附件 ，不能被删除
    if (this.type == 'addlddj') {
      console.log(this.type)
      this.regitsterInfo.currentAddressCode = this.$store.state.defaultAddress.addressFourCode.split(',');//四级现住地code
      this.regitsterInfo.currentAddress = this.$store.state.defaultAddress.addressFourDetail;//四级现住地文字
      
      let a = { ...this.regitsterInfo, ...this.rowdata }; //解构
      if (this.rowdata.cardType == null) {
        a.cardType = "1";
      }
      // this.ldcs(this.rowdata.eventId)
      console.log(a,'打印aaaaaaaaa')
      this.regitsterInfo = a; //流调人员回显
      if (this.regitsterInfo.cardNo != "") {
        // console.log('lkkjmmmmmmmm')
        this.idCodeCount(this.regitsterInfo.cardNo)
      }
      // console.log(this.rowdata.eventName,'打印this.rowdata.eventName')
      this.regitsterInfo.eventName = this.rowdata.eventName;
      console.log(this.regitsterInfo.eventName,'this.regitsterInfo.eventName')
      // console.log(this.rowdata, '打印rowdata')
      console.log(this.regitsterInfo, '打印流调登记的信息')
      this.regitsterInfo.taskRemark = this.regitsterInfo.taskDesc;
      this.regitsterInfo.taskSource = this.regitsterInfo.infoSource;
      this.regitsterInfo.taskSourceCode = this.regitsterInfo.infoSourceCode
        ? this.regitsterInfo.infoSourceCode.split(",")
        : [];

      // 文件回显（做权限）
      if (a.files && a.files.length > 0) {
        const nextFileList = a.files
          .filter((o) => o.url)
          .map((o) => ({ ...o, disableRemove: true }));
        this.fileList = [...nextFileList];
        this.tempFileList = [...nextFileList];
      }
      
    }





    // 如若是 编辑部分点进来进行的回显（以及接口调整update）待做（处理一些字段字符串 转为数组回显和时间格式处理。）在getSurveyInfo处理
    if (this.type == 'edit') {
      this.getSurveyInfo(this.id); // 查询个人详情
    }
  },
  methods: {
    // 返回上一页面（返回父组件页面）
    goBack() {
      this.$parent.showAddPositive = false;
    },
    threeAddress() {
      this.regitsterInfo.taskSourceCode = this.$store.state.defaultAddress
        .addressThreeCode
        ? this.$store.state.defaultAddress.addressThreeCode.split(",")
        : []; //三级任务来源地区
      this.regitsterInfo.taskSource =
        this.$store.state.defaultAddress.addressThreeDetail; //三级 任务来源所属地区
    },

    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    //获取事件列表
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
        if (this.rowdata.eventName) {
          let a = { id: this.rowdata.eventId };
          console.log(a, '打印啊')
          this.ldcs(a);
        }
      }
    },

    // 获取调查场所
    async ldcs(v) {
      console.log(v,'打印vvvvvvvv')
      if (this.regitsterInfo.eventId) {
          // this.regitsterInfo.eventName = v.eventName;

        if(this.type !='addlddj'){
          this.regitsterInfo.eventName = v.eventName;
        }
      }
      let params = { eventId: v.id };
      const { data } = await getPlaceList({ params });

      if (data.code == "200") {
        this.eventcsSelect = data.data;
      }
    },
    // 清空协查函类别
    infoTypeChange() {
      this.regitsterInfo.infoTypeOther = "";
      this.regitsterInfo.letterNo = "";
    },
    // 当事件名称变化时，清空调查场所
    issueName() {
      this.regitsterInfo.surveyAddress = "";
    },
    // 是否接种了疫苗
    isVaccination() {
      this.regitsterInfo.vaccinationType = [];
      this.regitsterInfo.vaccinationTypeOther = "";
      this.regitsterInfo.inoculationNum = "";
      this.regitsterInfo.finishVaccination = "";
      this.regitsterInfo.lastNeedlesDate = null;
    },
    // 清空其他疫苗input框
    isOtherVaccination(value) {
      // console.log(value,'打印value')
      if (!value.includes('其他')) {
        this.regitsterInfo.vaccinationTypeOther = "";
      }
    },
    // 清空是否有自觉症状选择的，症状时间
    isClinicalSymptoms() {
      this.regitsterInfo.firstSymptomDate = null;
    },
    // 清空关联姓名
    isContactInfected() {
      this.regitsterInfo.associatedCaseName = "";
    },
    // 清空接触方式其他input框
    contactModeChange() {
      this.regitsterInfo.contactModeOther = "";
    },
    // 清空接触地点类型 其他输入框
    contactPlaceChange() {
      this.regitsterInfo.contactPlaceTypeOther = "";
    },
    // 清空其他感染途径
    routeInfectionChange() {
      this.regitsterInfo.routeInfectionOther = "";
    },

    //上传文件大小限制
    beforeUpload(file) {
      let size = file.size / 1024 / 1024 < 20;
      if (!size) {
        this.$message.warning("上传文件大小不能超过20M");
        return false;
      }
    },
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.tempFileList.push({
          name: file.response.data.originalName,
          url: file.response.data.imgUrl,
        });
      }
    },
    //文件上传失败
    handelError(error) {
      if (res.code == 200) {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },
    // //文件删除询问
    // beforeRemove() {
    //   return this.$confirm("确定要删除文件吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   });
    // },

    //文件删除提示询问（做权限）
    beforeRemove(file) {
      // console.log(file, 'file-before-remove')
      if (file.disableRemove) {
        this.$confirm(
        "该文件不可以删除",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      return !file.disableRemove;
      } else {
        return this.$confirm("确定要删除文件吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },

    //文件删除
    handleRemove(file) {
      if (file.status != "success") return;
      const deleteIndex = this.tempFileList.findIndex((item) => {
        if (file.response) {
          return item.url == file.response.data.imgUrl;
        } else {
          return item.url == file.url;
        }
      });
      this.tempFileList.splice(deleteIndex, 1);
    },
    //文件下载
    handlePreview(file) {
      let loadingInstance = Loading.service();
      Loading.service();
      if (file.status != "success") return;
      let fileUrl;
      if (file.response) {
        fileUrl = file.response.data.imgUrl;
      } else {
        fileUrl = file.url;
      }
      //获取文件后缀
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (["png", "jpg"].includes(suffix)) {
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.target = "_blank";
        alink.download = file.name; //fileName保存提示中用作预先填写的文件名
        alink.click();
      } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
        importUrlFile(fileUrl, file.name);
      } else {
        return;
      }
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },

    //获取调查人员
    async getUserListByPeopleType() {
      const params = { peopleType: 4 };
      const { data } = await getUserListByPeopleTypeApi({ params });
      if (data.code == "200") {
        this.surveyUserNameSelect = data.data;
      }
    },
    //选择调查人员
    handleSurveyUserName(item) {
      this.regitsterInfo.surveyUserName = item.name;
      this.regitsterInfo.surveyUserId = item.id;
    },

    // 获取组织架构列表
    async getOrgQueryList() {
      const { data } = await getMedicalOrgList();
      if (data.code == "200") {
        this.surveyObjectBelongOrgSelect = data.data;
      }
    },

    //选择所属社区
    handelSurveyObjectBelongOrg(value) {
      this.regitsterInfo.surveyObjectBelongOrg = value.orgName;
      this.regitsterInfo.surveyObjectBelongOrgCode = value.orgCode;
    },

    // 身份证计算
    idCodeCount(id) {
      if (id && id.length == 18) {
        let obj = getIdNumberInfo(id);
        this.regitsterInfo.sex = obj.sex == 0 ? "1" : "0";
        this.regitsterInfo.age = obj.age;
      } else {
        this.regitsterInfo.sex = "";
        this.regitsterInfo.age = "";
      }
    },

    // 清空表单（调查对象类别）
    surveyCategoryChange() {
      this.regitsterInfo.respondentsCategoryOther = '';//清空其他类别输入框
    },


    //现住地
    handelCurrentAddress(value) {
      // console.log("选择完成时触发", "value");
      // console.log(value, 'value是11111111111111111111111111111区域编码');
      let node = this.$refs.currentAddressRef.getCheckedNodes()[0];
      if (node) {
        let currentAddress = node.pathLabels.toString();
        this.regitsterInfo.currentAddress = currentAddress;
        this.regitsterInfo.currentAddressCode = value;
      }
    },
    //获取百度经纬度
    getBmap() {
      // console.log('获取百度经纬度')
      if (this.regitsterInfo.currentAddress) {
        this.$jsonp("https://api.map.baidu.com/geocoding/v3", {
          address:
            this.regitsterInfo.currentAddress +
            this.regitsterInfo.currentAddressDetail,
          output: "json",
          ak: "K47zbc0avMITSmQCoPu3oU9Eg9HLsII4",
        })
          .then((json) => {
            this.regitsterInfo.currentAddressBdTuid =
              json.result.location.lng + "," + json.result.location.lat;
            const currentAddressGdTuid = this.bMapTransqqMap(
              json.result.location.lng,
              json.result.location.lat
            );
            this.regitsterInfo.currentAddressGdTuid =
              currentAddressGdTuid.lng + "," + currentAddressGdTuid.lat;
          })
          .catch((err) => { });
      }
    },
    //百度转高德
    bMapTransqqMap(lng, lat) {
      const X_PI = (Math.PI * 3000.0) / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      let amap_lng = z * Math.cos(theta);
      let amap_lat = z * Math.sin(theta);
      return {
        lng: amap_lng,
        lat: amap_lat,
      };
    },

    // 获取提交参数
    getFormData() {
      // 封装数组转字符串方法
      const joinArray = (arr) => {
        if (arr && Array.isArray(arr)) {
          return arr.join(",");
        }
        return "";
      };
      // 封装时间格式
      const formatDate = (date) => {
        if (date) {
          return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
        }
        return "";
      };
      let survey = { ...this.regitsterInfo, preTaskUserId: this.type == 'addlddj' ? this.rowdata.id : "" };
      survey.firstSymptomDate = formatDate(survey.firstSymptomDate); //最早出现症状时间
      survey.lastNeedlesDate = formatDate(survey.lastNeedlesDate); //最后针剂时间
      survey.firstNucleicPositiveDate = formatDate(survey.firstNucleicPositiveDate);//首次核酸阳性的采集日期
      survey.contactDate = formatDate(survey.contactDate);//接触时间
      survey.lastContactDate = formatDate(survey.lastContactDate);//末次接触时间
      survey.vaccinationType = survey.vaccinationType.join(",")
      survey.currentAddressCode = survey.currentAddressCode.join(",")
      survey.taskSourceCode = survey.taskSourceCode.join(",");
      return {
        survey,
        files: this.tempFileList,
      };
    },


    // 校验提交按钮
    async submitForm() {
      let flag = true;
      try {
        await this.$refs.regitsterInfoForm.validate();
      } catch (e) {
        this.$message({
          message: e.message || "必填项未填写或填写格式不符合",
          type: "warning",
          offset: 60,
        });
        // 有错误应该return掉
        flag = false;
        return;
      }
      if (flag) {
        // console.log('校验成功')
        this.submitLoading = true;
        this.checkIsReg();
      }
    },

    async checkIsReg() {
      let params = this.getFormData();
      console.log(params, '打印提交参数')
      if (this.type == 'edit') {
        this.postSubmit(); //编辑不查重
      }

      if (this.type == 'addPositive' || this.type == 'addlddj') {
        const res = await checkPositiveIsReg(params);
        // console.log(res,'res打印ressss打印查重的返回整个')
        // return
        if (res.data.data == null) {
          // this.submitLoading = false;
          // 没有重复人员，直接调用保存提交
          this.postSubmit();
        } else if (this.dialogVisible == false) {
          //打开警告框
          this.dialogVisible = true;
          this.dialogMessage = res.data.data;
          // this.isReg = res.data.data;
        }
      }
    },

    // 关闭警告框
    closeDialog() {
      this.dialogVisible = false;
      this.submitLoading = false;
    },

    // 提交请求
    async postSubmit() {
      this.continueSubmitLoading = true;
      let params = this.getFormData();
      // console.log(params,'打印params')
      if (this.type == 'addPositive' || this.type == 'addlddj') {
        // 新增保存接口
        let result = await saveSurveyPositiveInfo(params);
        // console.log(result,'result。。。。。')
        if (result.status == 200) {
          // this.continueSubmitForm = false;
          this.submitLoading = false;
          this.continueSubmitLoading = false;
          this.dialogVisible = false;
          if (result.data.code == "200") {
            this.$message({
              message: '提交成功',
              type: "success",
            });
            this.goBack();
            // this.$router.go(0)
            // this.$forceUpdate()
          }
        }
      }
      // 如果为编辑点进来的
      if (this.type == 'edit') {
        let result = await updateSurveyPositive(params);
        if (result.status == 200) {
          // this.continueSubmitForm = false;
          this.submitLoading = false;
          this.continueSubmitLoading = false;
          this.dialogVisible = false;
          if (result.data.code == "200") {
            this.$message({
              message: '修改成功',
              type: "success",
            });
            this.goBack();
            // this.$router.go(0)
          } else {
            this.$message({
              message: result.data.message,
              type: "success",
            });
          }
        }
      }
    },

    // 回显登记详情
    async getSurveyInfo(personId) {
      let id = personId;
      // const params = { id: id };
      // console.log("row", this.rowdata);
      // this.regitsterInfo = this.rowdata; //流调人员回显
      if (id) {
        let res = await getPositiveInfo(id);
        console.log(res.data)
        if (res.data.code == "200") {
          // console.log("登记详情", data.data);
          // 调用调查场所
          let nzgsdwr = {
            // id: res.data.data.survey.eventId==null?"":res.data.data.survey.eventId,
            id: res.data.data.survey.eventId,
          };
          console.log(nzgsdwr, '打印nzgsdwr')
          this.ldcs(nzgsdwr);
          this.regitsterInfo = res.data.data.survey;

          if (res.data.data.surveyFileList) {
            for (let i in res.data.data.surveyFileList) {
              if (res.data.data.surveyFileList[i].fileUrl) {
                console.log('kkkkkklllll')
                let a = {
                  name: res.data.data.surveyFileList[i].fileName,
                  url: res.data.data.surveyFileList[i].fileUrl,
                };
                this.fileList.push(a);
                this.tempFileList = this.fileList;
              }
            }
          }
          //处理省市区回填
          this.regitsterInfo.taskSourceCode = this.regitsterInfo.taskSourceCode
            ? this.regitsterInfo.taskSourceCode.split(",")
            : [];
          this.regitsterInfo.currentAddressCode = this.regitsterInfo
            .currentAddressCode
            ? this.regitsterInfo.currentAddressCode.split(",")
            : [];
          this.regitsterInfo.vaccinationType = this.regitsterInfo
            .vaccinationType
            ? this.regitsterInfo.vaccinationType.split(",")
            : [];
          this.echoAddress = true; //暂时注释掉，可能会使用在地区上面。
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      font-size: 12px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.content-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px  solid red;
}

.content-item1 {
  width: 100%;
  height: 100%;
}


.special {
  flex: 1;
}

.content-item9{
  width: 100%;
  display: flex;
  // border: 1px  solid red;
  // justify-content: space-between;
  flex-wrap: nowrap;
  // flex-direction: column;
}
.fouritem {
  width: 530px;
  // border: 1px solid red;
  overflow: hidden;
}
.threeitem{
  width: 15%;
  // border: 1px solid red;
  overflow: hidden;
}

// .special1 {}

.formitem {
  width: 50%;
  // border: 1px solid red;
}

.select-width {
  width: 90%;
  // width: 300px;
  // border: 1px solid red;
}

.select-width2 {
  width: 300px;
  margin-left: 30px;
  // border: 1px solid red;
}

.select-width3 {
  width: 300px;
  margin-left: 0px;
  // border: 1px solid red;
}


// .btns {
//   display: flex;
//   justify-content: center;
// }

.warning {
  margin-bottom: 20px;
  padding-left: 20px;
}

.ph {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 40px;
}

.classify {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.classify .select-width {
  width: 240px;
}

.classify .other-input {
  width: 200px;
}

.other-input {
  margin-left: 10px;
}

.el-icon-warning-outline {
  color: #ff9900;
  font-size: 26px;
  font-weight: bold;
  margin-right: 10px;
}

.notification {
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
  margin: 20px auto;
  justify-content: space-around;

  .NameNotification {
    width: 10%;
  }

  .CardIdNotification {
    width: 40%;
  }

  .OrgPhoneNotification {
    width: 35%;
  }

  .TypeNotification {
    width: 15%;
  }
}

.btnbox {
  height: 50px;
}

.btns {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 60px;
  bottom: 10px;
  width: 120px;
  z-index: 999;
}

.textarea {
  width: 94%;
}
</style>
